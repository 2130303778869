<div class="breadcrumb">
    <div class="breadcrumb__item">
        <img src="../../../../assets/img/icons/home__icon.svg" height="24" width="24" alt="{{homeTitle}}"
            class="home-icon">
        <a href="/">{{homeTitle}}</a>
    </div>

    <span class="item__arrow"></span>

    <ng-container *ngFor="let node of breadcrumbNodes; let lastNode = last">
        <div class="breadcrumb__item">
            <a [href]="node.slug" [ngClass]="{'item__primary': lastNode}">{{node.title}}</a>
        </div>
        <span *ngIf="!lastNode" class="item__arrow"></span>
    </ng-container>
<div>