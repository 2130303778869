import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationServiceModule } from './business/api/navigation.service.module';
import { PageLayoutModule } from './presentation/layout/page-layout/page-layout.module';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { coreReducer } from './store/core.reducer';
import { inspectionReducer } from './store/inspection.reducer';
import { RecaptchaService } from './presentation/ui/services/recaptcha-service';
import { GtmService } from './presentation/ui/services/gtm.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

export function loadRecaptcha(recaptchaService: RecaptchaService) {
    return () => recaptchaService.loadRecaptcha();
}

export function loadGtm(gtmService: GtmService) {
    return () => gtmService.loadGtm();
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        PageLayoutModule,
        NavigationServiceModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        StoreModule.forRoot({  core: coreReducer, inspection: inspectionReducer }),
        RecaptchaV3Module,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: loadRecaptcha,
          deps: [RecaptchaService],
          multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: loadGtm,
            deps: [GtmService],
            multi: true
          }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
