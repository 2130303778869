import { createAction, props } from '@ngrx/store';
import { IInspector } from 'src/app/business/inspection/models/inspector.model';

export const selectInspector = createAction(
  '[Inspection] Select Inspector',
  props<{ inspector: IInspector }>()
);

export const inspectorPortalUrl = createAction(
  '[Inspection] Inspector Portal Url',
  props<{ url: string }>()
);