export const environment = {
    production: true,
    strapiUrl: "https://cms.riv.ca",
    apiPath: "api",
    API_URL: 'https://liisbweb.smartborder.com/RivAPI6',
    API_LEGACY: 'https://www.riv.ca/RivolutionAPI/api',
    strapiToken: "cfaa4f54143fbf21475fae681bb658d9debbcf2dbcf18706e5ec8199fcfadc089651d536eb5dc03f6b49be16c19b15bade1ad2aa754e621ccdd47da3a243ee8ef47a527aea7831ff43a0379ed7e336c75e94866f5c3f41ce29f6c36755c0dfa3010427e4a1fc97d43989637f8c048ea187363f05ce853a2be40c7e2458acaf09",
    recaptcha: {
        siteKey: '6Lf1BS0qAAAAAI20CYQjWKDt2q03SdLuMZOg-lcr',
    },
    gtm: {
        id: 'G-MGGGPE0V28',
        containerId: 'GTM-M6PFZ3J2'
    },
    STRIPE_API_KEY: 'pk_live_51Mb5bHE1bkj1VmdozwM6hDBMFEweHKClta1ErhKNfEDRoZqxYtbyhKLTOJZDZTkylEy68z1d0T3OutbZVZk3V5tq00CIHftnLl',
};
