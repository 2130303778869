import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'riv-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

    @Input() text: string;
    @Input() isDisabled: boolean;
    @Input() set buttonType(value: 'primaryButton' | 'secondaryButton' | 'secondaryAltButton') {
        switch (value) {
            case 'primaryButton':
                this.buttonClass = 'primary';
                break;
            case 'secondaryButton':
                this.buttonClass = 'secondary';
                break;
            case 'secondaryAltButton':
                this.buttonClass = 'secondary-alt';
                break;
        }
    };

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    buttonClass: string;
    constructor() { }

}
