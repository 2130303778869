import { createReducer, on } from "@ngrx/store";

import { IInspectionState } from "../business/inspection/models/inspection-state.model";
import * as InspectionActions from "./actions/inspection.actions";

const initialState: IInspectionState = {
    selectedInspector: null,
    inspectorPortalUrl: ""
}

export const inspectionReducer = createReducer(
    initialState,
    on(InspectionActions.selectInspector, (state, { inspector }) => {
        return {
            ...state,
            selectedInspector: inspector,
        };
    }),
    on(InspectionActions.inspectorPortalUrl, (state, { url }) => {
        return {
            ...state,
            inspectorPortalUrl: url,
        };
    }),
);  