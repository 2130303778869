import { DomSanitizer } from '@angular/platform-browser';
import { Injectable, SecurityContext } from '@angular/core';
import { environment } from 'src/environments/environment';
import { htmlEntities } from './model/html-entities';
@Injectable({
    providedIn: 'root',
})
export class SanitizationService {
    constructor(private sanitizer: DomSanitizer) {}

    uploadsUrl = environment.strapiUrl + '/uploads/';

    sanitize(data) {
        return this.sanitizer.sanitize(SecurityContext.URL, data) || '';
    }

    sanitizeHtml(html) {
        return this.sanitizer.bypassSecurityTrustHtml(
            html.replaceAll('/uploads/', this.uploadsUrl),
        );
    }

    sanitizeHtmlNoReplace(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    sanitizeImageUrl(url) {
        return url.replaceAll('/uploads/', this.uploadsUrl);
    }

    bypassUrlSecurity(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    convertEntitiesToCharacters(text) {
        for (var entity in htmlEntities) {
          if (htmlEntities.hasOwnProperty(entity)) {
            text = text.replace(new RegExp(entity, 'g'), htmlEntities[entity]);
          }
        }
        return text;
    }

    cleanInlineStyles(text) {
        const styleRegex = /style="[^"]*"/g;
        const clearedHtmlString = text.replace(styleRegex, '');
        return clearedHtmlString;
    }
    
    sanitizeTextFromXML(xmlString) {
        function decodeEntities(encodedString) {
            // Create a temporary DOM element to decode HTML entities
            var textarea = document.createElement('textarea');
            textarea.innerHTML = encodedString;
            return textarea.value;
        }
        
        // Function to recursively decode all text nodes
        function decodeTextNodes(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                node.nodeValue = decodeEntities(node.nodeValue);
            } else {
                for (var i = 0; i < node.childNodes.length; i++) {
                    decodeTextNodes(node.childNodes[i]);
                }
            }
        }
        // Parse the XML string
        var parser = new DOMParser();
        var xmlDoc: Document = parser.parseFromString(xmlString, 'application/xml');
    
        // Check for XML parsing errors
        if (xmlDoc.getElementsByTagName('parsererror').length > 0) {
            throw new Error('Error parsing XML');
        }
    
        // Start decoding from the root element
        decodeTextNodes(xmlDoc.documentElement);
    
        // Serialize the XML back to a string
        var serializer = new XMLSerializer();
        return serializer.serializeToString(xmlDoc);
    }

    stripHtmlTags(html: string): string {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }
}
