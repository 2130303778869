import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SanitizationService } from './business/common/sanitization.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'sbweb-riv';
    gtmContainerId = environment.gtm.containerId; 
    gtmUrl = '';

    constructor(
        private router: Router, 
        private renderer: Renderer2,
        private sanitizationService: SanitizationService
    ) {}

    ngOnInit() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.router.url.includes('contact-us') || this.router.url.includes('inspection-form')) {
            this.renderer.addClass(document.body, 'show-recaptcha');
          } else {
            this.renderer.removeClass(document.body, 'show-recaptcha');
          }
        }
      });
    }

    bypassUrlSecurity(url) {
      return this.sanitizationService.bypassUrlSecurity(url);
    }
}
